<template>
    <div>
        <psi-page-title :title="page.title" :icon="page.icon" :breadcrumbs="breadcrumbs"></psi-page-title>
        <application-uploads-toolbar></application-uploads-toolbar>
        <router-view></router-view>
    </div>
</template>

<script>
export default {
    name: "Uploads",
    components: {
        ApplicationUploadsToolbar: () =>
            import("@components/uploads/components/ApplicationUploadsToolbar"),
    },
    data: () => ({
        page: {
            title: "Application Uploads",
            icon: "mdi-account",
        },
        breadcrumbs: [
            {
                text: "Dashboard",
                disabled: false,
                to: "#",
            },
            {
                text: "Application Uploads",
                disabled: true,
            },
        ],
    }),
};
</script>